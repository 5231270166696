/*
Theme Name: Sushikiriz
Theme URI: https://bravad.ca
Author: Sébastien Gagné
Author URI: https://bravad.ca
Description: Wordpress bravad theme (Sushikiriz)
Version: 2.2.2
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: bravad
*/

:root{
	--blue: #00447B;
}

a, .primary-nav .menu a:hover, .primary-nav .menu>.current-menu-item>a {color:var(--blue);}
.primary-nav .menu>.current-menu-item>a:before{background-color:var(--blue);}

.site-logo img{height: 120%}

@media (max-width: 992px){
	.site-logo img{height: 100%;}
}

.primary-nav .menu>.menu-item-has-children>a:after {background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%2300447B;%7D%0A%3C/style%3E%3Cpath class='st0' d='M24,8c0,0.4-0.2,0.7-0.5,0.9l-10.8,8c-0.4,0.3-0.9,0.3-1.3,0L0.5,9C0,8.6-0.1,7.9,0.2,7.4 c0.4-0.5,1.1-0.6,1.6-0.3L12,14.5l10.2-7.4c0.5-0.4,1.2-0.3,1.6,0.3C23.9,7.6,24,7.8,24,8L24,8z'/%3E%3C/svg%3E")}
.primary-nav .sub-menu>.current-menu-item>a {background-color: #fff; color: var(--blue)}

.hero-caption h1{color:#fff; font-weight: bold; text-shadow:1px 1px 3px #000;}

.block-title{font-weight: bold; color: var(--blue);}

.btn-primary{background-color:var(--blue); border:0;}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary:not(:disabled):not(.disabled):active{background-color:var(--blue); opacity: 0.95;}

#page-footer{position: relative; padding-top:50px;}
#page-footer .block-title{color: #fff}
#page-footer:before{content:'';position: absolute; left:0; top:0; width:100%; height:100%; background:rgba(0,0,0,0.5);z-index: 1;}
#page-footer .btn-primary{ box-shadow:0px 0px 10px var(--blue); padding:20px 60px; }
#page-footer .btn-primary:hover{box-shadow:0px 0px 10px #868181;}

.page-footer{background:#fff; color:#000;}
.page-footer h3{font-weight: 400; color: #000}
.page-footer a{color:#000;}

.block-sale-points{padding: 10rem 0 0}
.gm-style .gm-style-iw-c{width: 300px;}

.more-padding{padding: 30px 15px}